<template>
  <div>
    <b-card>
      <b-col>
        <b-table :items="getFormaTable()" id="table-activity" :fields="$t(`table_activity`)" head-variant="light"
          td-class="d-flex" responsive :busy="!loaded" outlined show-empty>
          <template #cell(id)="data">
            <span>{{ data.item.id.id }}</span>
          </template>
          
          <template #cell(activity)="data">
            <span>{{ data.item.actitvity }}</span>
          </template>
          
          <template #cell(date)="data">
            <span>{{ data.item.date }}</span>
          </template>

          <template #empty>
            <no-Results
              v-if="dataTable.length === 0"
              :title="$t('profile.noData')"
              :show_button= "false"
              :border="'border-0-custom-activity-table'"
            />
          </template>

          <template #table-busy>
            <div class="text-center my-2 h-min-empty d-flex align-items-center justify-content-center">
              <b-spinner class="align-middle"></b-spinner>
            </div>
        </template>
        </b-table>
        <b-pagination :total-rows="total_count" :per_page="size_page" v-model="page" aria-controls="table-activity" class="d-flex justify-content-center" v-if="dataTable.length !== 0 && loaded"></b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCol, BTable, BSpinner, BPagination } from 'bootstrap-vue'
import service from '@/services/notifications.js';
import { getDatev2 } from "@/libs/utils/times";

export default {
  name: "settingActivity",
  components: {
    BCard,
    BCol,
    BTable,
    BSpinner,
    BPagination,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
  },

  data() {
    return {
      getDatev2,
      dataTable: [],
      page: 1,
      size_page: 20,
      total_count: 0,
      loaded: false,
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.loaded = false;
      service.activityNotification(this.size_page, this.page).then(response => {
        this.dataTable = response.results;
        this.total_count = response.count;
        setTimeout(() => {
          this.loaded = true;
        }, 500);
      })
    },
    updatePage(current_page_new) {
      this.page = current_page_new;
      this.getData();
    },
    getFormaTable() {
      const data = [];
      this.dataTable.map((item, index) => {
        data.push({
          id: { id: index + 1, uuid: item.uuid },
          actitvity: item.message,
          date: getDatev2(new Date(item.created_at))
        });
      });
      return data;
    }
  },
  watch: {
    page() {
      this.getData();
    }
  }
};
</script>
<style> 
.border-0-custom-activity-table {
  border: none !important;
}
</style>
<style scoped lang="scss">
.h-min-empty {
  height: 760px;
}

</style>