<template>
  <b-sidebar
    v-model="show"
    @change="closeSider"
    right
    shadow
    backdrop
    header-class="container-title-sider"
    width="400px"
  >
    <template #header>
      <div>
        <span class="title-sider">{{ payoneer === true ? $t("titlePayoutPayoneer") : $t("titlePayout") }}</span>
        <feather-icon
          class="close-sider-network"
          icon="XIcon"
          size="20"
          @click="show = false"
        />
      </div>
    </template>
    <div class="mt-4 px-2">
      <validation-observer #default="{ invalid }">
        <b-form class="col-12" @submit.prevent="saveEmail">
          <b-form-group :label="payoneer === true ? $t('creator.currentEmailPayoneer') : $t('creator.currentEmail')">
            <validation-provider rules="required|email">
              <b-form-input
                size="lg"
                :placeholder="payoneer === true ? $t('creator.payoneerEmail') : $t('creator.paypalEmail')"
                v-model="currentEmail"
              />
            </validation-provider>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <b-button
              type="submit"
              :disabled="invalid"
              class="blue-button btn-position"
              variant="blue-button"
            >
              {{ $t("save") }}</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
    
<script>
import {
  BSidebar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import service from "@/services/others";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "SiderPaypal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BForm,
    BFormGroup,
    BSidebar,
    BButton,
  },
  props: {
    payoneer: {
      type: Boolean,
    },
    show_sider_paypal: {
      type: Boolean,
    },
    profile: {
      type: Object,
    }
  },
  data() {
    return {
      required,
      email_paypal: '',
      email_payoneer: '',
      show: this.show_sider_paypal,
    };
  },
  computed: {
    currentEmail: {
      get() {
        return this.payoneer ? this.email_payoneer : this.email_paypal;
      },
      set(value) {
        if (this.payoneer) {
          this.email_payoneer = value;
        } else {
          this.email_paypal = value;
        }
      }
    }
  },
  created() {
    this.email_paypal = this.profile && this.profile.paypal_info && this.profile.paypal_info.email_paypal ? this.profile.paypal_info.email_paypal : this.email_paypal;
    this.email_payoneer = this.profile && this.profile.paypal_info && this.profile.paypal_info.email_payoneer ? this.profile.paypal_info.email_payoneer : this.email_payoneer;

  },
  methods: {
    closeSider(value) {
      if (!value) this.$emit("close_siderbar_paypal") 
    },
    saveEmail () {
      if (!this.profile.payment_data?.was_accepted) {
        this.$emit('open_confirm');
        return;
      }
      this.serviceSaveEmail();
    },
    serviceSaveEmail() {
      let meta = {};
      if (this.payoneer) {
        meta = {
          email_payoneer: this.email_payoneer,
        };
      } else {
        meta = {
          email_paypal: this.email_paypal,
        };
      }
      service.addEmailPaypal(meta)
        .then(response => {
          if (response.status === 400) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:  this.$t('err'),
                text: this.$t('messageAccountSettingError'),
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('dataUpdated'),
                icon: 'CheckCircleIcon',
                variant: 'success',
                text:  this.$t('dataSuccess')
              }
            })
            this.$emit("update_payment_data", response);
            this.show = false
          }
        })
    },
    showToast(
      title,
      text,
      variant,
      icon = "AlertCircleIcon",
      position = "top-right"
    ) {
      this.$toast({
        component: ToastificationContent,
        position,
        props: {
          title,
          icon,
          variant,
          text,
        },
      });
    },
  },
};
</script>
  <style scoped>
</style>