<template>
  <b-card>
    <validation-observer ref="newNetForm" #default="{ invalid }">
      <b-form @submit.prevent="addBrand">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="LinkIcon" size="18" />
              <h4 class="mb-0 ml-75">
                {{ $t("brand") }}
              </h4>
            </div>
          </b-col>

          <!-- brand input-->
          <b-col md="5">
            <b-form-group>
              <label for="brand">{{ $t("writeBrand") }}</label>
              <validation-provider
                name="$t('brandname')"
                vid="$t('brandname')"
                rules="required"
              >
                <b-form-input id="brand" v-model="newBrand.name" @input="chechCharLimit"/>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ brand -->
          <b-col md="2" class="p-2">
            <b-button :disabled="invalid" type="submit" variant="primary">{{  $t("add")  }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row>
      <!-- brands Connections -->
      <b-col cols="12" class="mt-1">
        <div class="d-flex align-items-center mb-3">
          <feather-icon icon="UserIcon" size="18" />
          <h4 class="mb-0 ml-75">
            {{ $t("connected") }}
          </h4>
        </div>
        <b-row class="text-center">
          <!-- brands -->
          <b-col
            v-for="(brand, x) in brands"
            :key="x"
            md="3"
            cols="6"
            class="mb-1"
          >
            <b-card-text class="font-weight-bold">
              {{ capitalString(brand.name) }}
            </b-card-text>
            <div class="mb-1">
              <b-avatar
                size="60"
                :text="capitalString(getAvatarBrand(brand.name))"
              >
              </b-avatar>
            </div>

            <b-link disabled @click="deleteNetwork(brand)" style="color: #C4C8CC; cursor:default">
              {{ $t("delete") }}
            </b-link>
          </b-col>
          <!--/ brands -->
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import service from "@/services/others";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { capitalize } from '@/libs/utils/formats';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      capitalize,
      required,
      brands: [],
      newBrand: {
        name: "",
      },
      isLimit: false
    };
  },
  created() {
    localize("es");
    this.getBrands();
  },
  methods: {
    resetForm() {
      this.newBrand = {
        name: "",
      };
    },

    getBrands() {
      service.getBrands().then((response) => {
        this.brands = response.results;
      });
    },
    addBrand() {
      this.$refs.newNetForm.validate().then((valid) => {
        if (valid && !this.isLimit) {
          service.addBrand(this.newBrand).then(() => {
            this.resetForm();
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t("brandAdd"),
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: this.$t("socialSuccess"),
                }
              })
            })
            this.getBrands();
          });
        } else {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: "Error",
                icon: "AlerCircletIcon",
                variant: "danger",
                text: this.$t("limitExceeded"),
              }
            })
          })
        }
      });
    },
    chechCharLimit() {
      this.isLimit = this.newBrand.name.length > 50;
    },
    deleteNetwork(brand) {
      console.log(brand)
      // service.removeBrand(brand.uuid).then((response) => {
      //   console.log(response);
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: "top-right",
      //     props: {
      //       title: this.$t("socialDeleted"),
      //       icon: "AlertIcon",
      //       variant: "danger",
      //       text: this.$t("socialDeleteddata"),
      //     },
      //   });
      //   this.getNetworks();
      // });
    },
    capitalString(string) {
      return capitalize(string);
    },

    getAvatarBrand(string) {
      const newString = string[0] + string[1];
      return newString;
    },
  },
};
</script>
