<template>
  <b-modal 
    v-model="show"
    class="text-center"
    centered
    title="Publicaciones instagram"
    hide-footer
    size="xl"
  >
    <b-row class="match-height">
      <b-col
        cols="12"
        v-for="media in medias"
        :key="media.uuid"
        class="col-md-6 col-lg-4 col-xl-4"
      >
        <b-card
          :img-src="getImage(media)"
          img-alt="Image"
          img-top
          style="object-fit: cover"
          class="cardImage"
          ref="card"
        >
          <p class="mb-0">{{media.caption}}</p>
          <b-row class="justify-content-around align-items-center mt-1">
            <b-col sm="6" md="3">
              <div class="truncate d-flex justify-content-around">
                <h5 class="mb-25 font-weight-bolder">
                  <feather-icon size="18" :icon="'MessageSquareIcon'" />
                </h5>
                <span>{{ media.comments_count }}</span>
              </div>
            </b-col>
            <b-col sm="6" md="3">
              <div class="truncate d-flex justify-content-around">
                <h5 class="mb-25 font-weight-bolder">
                  <feather-icon size="18" :icon="'HeartIcon'" />
                </h5>
                <span>{{ media.like_count }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="justify-content-around align-items-center mt-1">
            <b-col
              sm="6"
              md="3"
              v-for="metric in media.metrics"
              :key="metric.id"
            >
              <div class="d-grid text-center">
                <span>
                  {{metric.title}}
                  <feather-icon size="18" :icon="'InfoIcon'" :id="metric.id"/>
                </span>
                <span>{{metric.values[0].value}}</span>
                <b-tooltip
                  :target="metric.id"
                  triggers="hover"
                  placement="right"
                >
                  {{ metric.description }}
                </b-tooltip>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="text-right">
        <b-button variant="primary" @click="redirectProfile()">Ir a perfil</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { BModal, BButton, BRow, BCol, BCard, BTooltip } from 'bootstrap-vue'
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: 'ModalMediaInstagram',
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
    medias: {
      type: Array,
      default: () => []
    },
    username: {
      type: String,
      default: ''
    }
  },
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BCard,
    BTooltip
  },
  data () {
    return {
      getDatev2,
      show: this.modalShow,
    }
  },
  created() {
  },
  methods: {
    redirectProfile() {
      const url = `https://www.instagram.com/${this.username}`
      window.open(url, '_blank')
    },
    getImage(media) {
      if (media.media_type === 'IMAGE') return media.media_url
      else if (media.media_type === 'CAROUSEL_ALBUM') return media.media_url
      else return media.thumbnail_url
    },
    getFormatDate(date_string) {
      return getDatev2(new Date(date_string))
    }
  }
}
</script>

<style lang="scss" scoped>
  .d-grid {
    display: grid !important;
  }
</style>